declare module 'buefy/types' {
  export const Field: any
  export const Select: any
  export const Input: any
  export const Button: any
  export const Loading: any
  export const Icon: any
  export const Upload: any
  export const Datepicker: any
  export const Dialog: any
  export const Dropdown: any
  export const DropdownItem: any
  export const Notification: any
  export const Table: any
  export const TableColumn: any
  export const Radio: any
  export const RadioButton: any
  export const Checkbox: any
  export const Autocomplete: any
  export const Tabs: any
  export const TabItem: any
  export const Menu: any
  export const MenuList: any
  export const MenuItem: any
  export const Message: any
  export const Tooltip: any
  export const Tag: any
  export const Taginput: any
  export const Switch: any
  export const Toast: any
  export const Modal: any
  export const Collapse: any
}

import App from '@/App.vue'
import { AppConfig, getConfig } from '@/config'
import { vueApolloClient } from '@/graphql/client'
import router from '@/router'
import store, { SET_CONFIG, SET_USER_GATEWAY_FEATURES } from '@/store'
import { registerValidators } from '@/utils'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client/ApolloClient'
import { HttpLink } from 'apollo-link-http'
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  ConfigProgrammatic,
  Datepicker,
  Dialog,
  Dropdown,
  Field,
  Icon,
  Input,
  Loading,
  Menu,
  Message,
  Modal,
  Notification,
  Radio,
  Select,
  Switch,
  Table,
  Tabs,
  Tag,
  Taginput,
  Toast,
  Tooltip,
  Upload
} from 'buefy'
import { parse } from 'date-fns'
import get from 'lodash/get'
import { setInteractionMode } from 'vee-validate'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { queries } from './graphql'

const BaseLayout = () => import('@/layouts/BaseLayout.vue')
const NavBarLayout = () => import('@/layouts/NavBarLayout.vue')
const RightSidebarLayout = () => import('~/layouts/RightSidebarLayout.vue')
const BaseInput = () => import('~/components/BaseInput.vue')
const BaseSelect = () => import('~/components/BaseSelect.vue')
const BaseRow = () => import('~/components/BaseRow.vue')
const BaseNotification = () => import('~/components/BaseNotification.vue')
const AddressAutocomplete = () => import('~/components/AddressAutocomplete.vue')
const AddressFields = () => import('~/components/AddressFields.vue')

const RadioYesNoInput = () => import('~/components/RadioYesNoInput.vue')
const TableNoResults = () => import('~/components/TableNoResults.vue')
const BankGuaranteeAgencyAgreement = () => import('~/components/BankGuaranteeAgencyAgreement.vue')
const StandardFooterBankPortal = () => import('@/components/StandardFooterBankPortal.vue')
const GenericDocumentModal = () => import('~/components/GenericDocumentModal.vue')
const FSGDocumentModal = () => import('@/components/FSGDocumentModal.vue')
const VueSanitize = require('vue-sanitize')
Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(Field)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Collapse)
Vue.use(Loading)
Vue.use(Icon)
Vue.use(Upload)
Vue.use(Datepicker)
Vue.use(Dropdown)
Vue.use(Notification)
Vue.use(Table)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Autocomplete)
Vue.use(Tabs)
Vue.use(Menu)
Vue.use(Message)
Vue.use(Tooltip)
Vue.use(Tag)
Vue.use(Taginput)
Vue.use(Switch)
Vue.use(Toast)
Vue.use(Modal)
Vue.use(Dialog)
Vue.use(VueSanitize, {
  allowedTags: ['b', 'ul', 'li', 'h1', 'h2', 'h3', 'del', 'ins', 'button', 'div', 'p', 'span'],
  allowedAttributes: { '*': ['class', 'id', 'style'] }
})
const dateSplitter = ' - '
const dateFormatter = (date: Date) => date.toLocaleDateString('en-AU')
const dateParser = (date: string) => parse(date, 'dd/MM/yyyy', new Date())

ConfigProgrammatic.setOptions({
  defaultToastDuration: 4000,
  defaultIconPack: 'feather-icon',
  defaultLinkTags: ['a', 'button', 'input', 'router-link', 'RouterLink'],
  defaultNoticeQueue: false,
  defaultDateParser: (date: string) =>
    date.includes(dateSplitter) ? date.split(dateSplitter).map(dateParser) : dateParser(date),
  defaultDateFormatter: (dates: Date | Date[]): string =>
    Array.isArray(dates) ? dates.map(dateFormatter).join(dateSplitter) : dateFormatter(dates),
  customIconPacks: {
    'feather-icon': {
      sizes: {
        default: 'is-size-5',
        'is-small': null,
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'icon-',
      internalIcons: {
        information: 'info',
        alert: 'alert-triangle',
        'menu-down': 'chevron-down',
        'menu-up': 'chevron-up',
        'close-circle': 'x-circle'
      }
    }
  }
})

// Register global components
Vue.component('Layout', BaseLayout) // eslint-disable-line
Vue.component('NavBarLayout', NavBarLayout)
Vue.component('RightSidebarLayout', RightSidebarLayout)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseRow', BaseRow)
Vue.component('BaseNotification', BaseNotification)
Vue.component('AddressAutocomplete', AddressAutocomplete)
Vue.component('AddressFields', AddressFields)
Vue.component('RadioYesNoInput', RadioYesNoInput)
Vue.component('TableNoResults', TableNoResults)
Vue.component('BankGuaranteeAgencyAgreement', BankGuaranteeAgencyAgreement)
Vue.component('StandardFooterBankPortal', StandardFooterBankPortal)
Vue.component('GenericDocumentModal', GenericDocumentModal)
Vue.component('FSGDocumentModal', FSGDocumentModal)

// Set up form validation
registerValidators()
setInteractionMode('eager')

getConfig<AppConfig>(`/${process.env.VUE_APP_CONFIG_FILE}`).then(data => {
  store.commit(SET_CONFIG, data)

  Sentry.init({
    dsn: 'https://600a7e07186b4bb68860f15cac9358fb@o341662.ingest.sentry.io/5284744',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: process.env.VUE_APP_RELEASE,
    enabled: process.env.VUE_APP_RELEASE !== 'local',
    environment: data.environment.name
  })

  new Vue({
    beforeCreate() {
      const client = new ApolloClient({
        link: new HttpLink({
          uri: data.api.graphql_endpoint
        }),
        cache: new InMemoryCache()
      })
      client
        .query({
          query: queries.GATEWAY_FEATURES_QUERY
        })
        .then(result => {
          const features = get(result, 'data.features', {})
          if (features) {
            this.$store.commit(SET_USER_GATEWAY_FEATURES, features)
          } else {
            this.$buefy.toast.open({
              type: 'is-danger',
              message: 'Gateway features could not be found.'
            })
            router.push({ name: 'default' })
          }
        })
        .catch(error => {
          const { message } = error
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        })
    },
    router,
    store,
    render: h => h(App),
    apolloProvider: vueApolloClient()
  }).$mount('#app')
})
