import { toLongDate } from '@/utils'
import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'
import { toCurrency } from '~/utils'

export class channelPartnerHandleExportCSV {
  reportData: any
  channelPartnerName: string

  constructor(reportData: any, channelPartnerName: string) {
    this.reportData = reportData
    this.channelPartnerName = channelPartnerName
  }

  public async handleExportCSV() {
    const parsedData = this.reportData.map((row: any) => ({
      Month: row.monthYear,
      Total: toCurrency(row.amount, true),
      'Savings/Referral': toCurrency(row.savings, true),
      'Existing Clients #': row.existingClients,
      'New Clients #': row.newClients,
      'Documents #': row.numberOfDocuments
    }))
    const data = unparse(parsedData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false,
      escapeFormulae: true
    })
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, this.channelPartnerName + ` Report - ${toLongDate(new Date())}.csv`)
  }
}
