import router from '@/router'
import store, { APP_CONFIG, AUTH_MOD, ID_TOKEN, SIGN_OUT } from '@/store'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { from, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ToastProgrammatic } from 'buefy'
import { GraphQLError } from 'graphql'
import VueApollo from 'vue-apollo'

let apolloClientHandle: any = null
let vueApolloClientHandle: any = null

const initialiseClient = () => {
  if (apolloClientHandle) {
    return {
      apolloClientHandle,
      vueApolloClientHandle
    }
  }

  const appConfig = store.getters[APP_CONFIG]
  const graphqlEndpoint = appConfig.api.graphql_endpoint
  const webSocketEndpoint = graphqlEndpoint.replace('https://', 'wss://').replace('http://', 'ws://')

  const protocolLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    new WebSocketLink({
      uri: webSocketEndpoint,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const token = await store.dispatch(`${AUTH_MOD}/${ID_TOKEN}`)
          return {
            authorization: `${token}`
          }
        }
      }
    }),
    new HttpLink()
  )

  const errorLink = onError(error => {
    if (containsErrorCode(error, 'AUTH_NOT_AUTHENTICATED')) {
      store.dispatch(`${AUTH_MOD}/${SIGN_OUT}`).then(() =>
        ToastProgrammatic.open({
          message: 'Your session has expired, please login again',
          type: 'is-danger'
        })
      )
    }
    if (containsErrorCode(error, 'AUTH_NOT_AUTHORIZED')) {
      router.push({ name: 'page-not-found' })
    }
  })

  const contextLink = setContext(async () => {
    const token = await store.dispatch(`${AUTH_MOD}/${ID_TOKEN}`)
    return {
      uri: graphqlEndpoint,
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })
  apolloClientHandle = new ApolloClient({
    link: from([errorLink, contextLink, protocolLink]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
      }
    },
    connectToDevTools: true
  })
  vueApolloClientHandle = new VueApollo({
    defaultClient: apolloClientHandle
  })

  return {
    apolloClientHandle,
    vueApolloClientHandle
  }
}

// This type is compatible with both ErrorResponse and ApolloError
interface GenericError {
  graphQLErrors?: ReadonlyArray<GraphQLError>
}

export const containsErrorCode: (error: GenericError, code: string) => boolean = (error, code) => {
  const { graphQLErrors } = error
  if (!graphQLErrors) {
    return false
  }
  return graphQLErrors.find(({ extensions }) => extensions && extensions.code === code) != null
}
export const vueApolloClient: () => VueApollo = () => initialiseClient().vueApolloClientHandle
export const apolloClient: () => ApolloClient<NormalizedCacheObject> = () => initialiseClient().apolloClientHandle
export const resetStore = async () => initialiseClient().apolloClientHandle.resetStore()
