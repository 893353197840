import {
  answerMfaChallenge,
  answerPasswordChallenge,
  configure,
  currentSession,
  currentUser,
  currentUserInfo,
  forgotPassword,
  forgotPasswordSubmit,
  signIn,
  signOut
} from '@/auth'
import { queries, resetStore } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import router from '@/router'
import { APP_CONFIG, RootState } from '@/store'
import { CognitoUser } from '@aws-amplify/auth'
import { ToastProgrammatic } from 'buefy'
import { isEmpty } from 'lodash'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
// Actions
export const FETCH_SESSION = 'fetchSession'
export const FETCH_CURRENT_USER = 'fetchCurrentUser'
export const FETCH_CURRENT_USER_INFO = 'fetchCurrentUserInfo'
export const SIGN_UP = 'signUp'
export const ID_TOKEN = 'idToken'
const CONFIGURE_CLIENT = 'configureClient'
export const FETCH_CURRENT_USER_DETAILS = 'fetchCurrentUserDetails'
export const SIGN_IN = 'signin'
export const SIGN_OUT = 'signOut'

// Mutations
const SET_USER = 'setUser'
const SET_SESSION = 'setSession'
const SET_USER_INFO = 'setUserInfo'
const SET_USER_DETAILS = 'setUserDetails'

// Getters
export const IS_LOGGED_IN = 'isLoggedIn'
export const GET_USER = 'getUser'
export const GET_USER_INFO = 'getUserInfo'
export const GET_USER_DETAILS = 'getUserDetails'
export const GET_USER_ROLES = 'getUserRoles'

interface CognitoState {
  session: any
  user: any
  challengeParamEmail: string | null
  userInfo: any
  userDetails?: CognitoUser
}

const state: CognitoState = {
  user: null,
  session: null,
  challengeParamEmail: null,
  userInfo: null,
  userDetails: undefined
}

const actions: ActionTree<CognitoState, RootState> = {
  async init({ dispatch }) {
    return await dispatch(CONFIGURE_CLIENT)
      .then(() => dispatch(FETCH_SESSION))
      .then(() => dispatch(FETCH_CURRENT_USER))
      .then(() => dispatch(FETCH_CURRENT_USER_INFO))
      .catch(() => {})
  },
  async [CONFIGURE_CLIENT]({ rootGetters }) {
    const config = await rootGetters[APP_CONFIG]
    configure(config)
  },
  async [FETCH_SESSION]({ commit }) {
    return await currentSession().then(session => commit(SET_SESSION, session))
  },
  async [FETCH_CURRENT_USER]({ commit }) {
    return await currentUser().then(user => commit(SET_USER, user))
  },
  async [FETCH_CURRENT_USER_INFO]({ commit, dispatch }) {
    return await currentUserInfo().then(userInfo => {
      if (isEmpty(userInfo)) {
        dispatch(SIGN_OUT)
      }
      commit(SET_USER_INFO, userInfo)
    })
  },
  async [FETCH_CURRENT_USER_DETAILS]({ commit }) {
    return await apolloClient()
      .query({
        query: queries.GET_ADMIN_USER
      })
      .then((userDetails: any) => {
        commit(SET_USER_DETAILS, userDetails?.data?.cognitoGetAuthenticatedUser)
      })
  },
  async [SIGN_IN]({ commit, dispatch, state }, payload: { email: string; password: string }) {
    const { email, password } = payload
    return await signIn(email, password)
      .then(user => {
        commit(SET_USER, user)
        commit(SET_SESSION, user.signInUserSession)
      })
      .then(currentUserInfo)
      .then(userInfo => {
        commit(SET_USER_INFO, userInfo)
        return getRouteFromCognitoChallenge(state.user.challengeName)
      })
  },
  async [SIGN_OUT]({ commit }) {
    return await signOut()
      .then(() => {
        commit(SET_SESSION, null)
        commit(SET_USER, null)
        commit(SET_USER_INFO, null)
        router.push({ name: 'account-login' })
      })
      .then(() => {
        resetStore()
        location.href = router.resolve({ name: 'account-login' }).href
        ToastProgrammatic.open({ message: "You've been logged out" })
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  async forgotPassword({ commit }, payload: { email: string }) {
    const { email } = payload
    return await forgotPassword(email)
  },
  async forgotPasswordSubmit({ commit }, payload: { username: string; code: string; newPassword: string }) {
    const { username, code, newPassword } = payload
    return await forgotPasswordSubmit(username, code, newPassword)
  },
  async answerPasswordChallenge({ state, commit }, answer: string) {
    return await answerPasswordChallenge(state.user, answer)
      .then(user => {
        commit(SET_USER, user)
        commit(SET_SESSION, user.signInUserSession)
      })
      .then(currentUserInfo)
      .then(userInfo => {
        commit(SET_USER_INFO, userInfo)
        return getRouteFromCognitoChallenge(state.user.signInUserSession?.accessToken || state.user.challengeName)
      })
  },
  async answerMfaChallenge({ state, commit }, answer: string) {
    return await answerMfaChallenge(state.user, answer)
      .then(user => {
        commit(SET_USER, user)
        commit(SET_SESSION, user.signInUserSession)
      })
      .then(currentUserInfo)
      .then(userInfo => {
        commit(SET_USER_INFO, userInfo)

        return getRouteFromCognitoChallenge(state.user.signInUserSession?.accessToken || state.user.challengeName)
      })
  },
  async [ID_TOKEN]({ state, dispatch }) {
    await dispatch(FETCH_SESSION)
    const {
      session: {
        idToken: { jwtToken }
      }
    } = state
    return jwtToken
  }
}

const getters: GetterTree<CognitoState, RootState> = {
  [IS_LOGGED_IN]({ session }) {
    return !!session
  },
  [GET_USER]({ challengeParamEmail }) {
    return challengeParamEmail
  },
  [GET_USER_INFO]({ userInfo }) {
    return userInfo
  },
  [GET_USER_DETAILS]({ userDetails }) {
    return userDetails
  },
  [GET_USER_ROLES]({ userInfo }) {
    if (isEmpty(userInfo)) return null
    return userInfo?.attributes['custom:roles']
  }
}

const mutations: MutationTree<CognitoState> = {
  [SET_USER](state, user) {
    state.user = user
  },
  setSession(state, session) {
    state.session = session
  },
  setChallengeParamEmail(state, email: string) {
    state.challengeParamEmail = email
  },
  [SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
  },
  [SET_USER_DETAILS](state, userDetails) {
    state.userDetails = userDetails
  }
}

const cognito: Module<CognitoState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export function getRouteFromCognitoChallenge(challenge: string) {
  switch (challenge) {
    case 'NEW_PASSWORD_REQUIRED':
      return { name: 'reset-password' }
    case 'SMS_MFA':
      return { name: 'otp' }
    default:
      return { path: '/complete' }
  }
}

export default cognito
