import gql from 'graphql-tag'

export const REPORTS_CHANGED_SUBSCRIPTION = gql`
  subscription ReportUpdated($userId: ID!) {
    reportUpdated(userId: $userId)
  }
`
export const REQUIRED_DOCUMENTS_UPDATED_QUERY = gql`
  subscription DraftUpdatedForBankPortal($draftId: ID!) {
    draftUpdated: draftUpdatedForBankPortal(draftId: $draftId)
  }
`
