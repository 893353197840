/**
 * Converts an object to an array of objects with key and value properties.
 *
 * @param {object} obj - The object to convert.
 * @returns {Array} - An array of objects with key and value properties.
 *
 * @example
 * // Output: [{ key: 'key1', value: 'value1' }, { key: 'key2', value: 'value2' }]
 */
export const convertObjectToKeyValueArray = (obj: object): Array<{ key: any; value: any }> => {
  return (Object.keys(obj) as (keyof typeof obj)[]).map(key => ({
    key,
    value: obj[key]
  }))
}
