interface AppConfig {
  cognito: {
    userPoolId: string
    userPoolWebClientId: string
    region: string
  }
  api: {
    graphql_endpoint: string
  }
  google: {
    apiKey: string
  }
  environment: {
    name: string
    referralUrl: string
  }
  greenId: {
    accountId: string
    isTestMode: boolean
    apiCode: string
  }
}

const getConfig = <T>(path: string): Promise<T> => {
  return fetch(path).then(response => response.json())
}

export { AppConfig, getConfig }
