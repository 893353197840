import { AppConfig } from '@/config'
import { queries } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import { BankDocument, BankDraft, GatewayFeatures } from '@/graphql/types'
import router from '@/router'
import { ApolloError } from 'apollo-client'
import { ToastProgrammatic } from 'buefy'
import get from 'lodash/get'
import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'
import cognitoModule, { IS_LOGGED_IN } from './cognito'
import referralPortalModule from './referralPortal'
import requiredDocumentsModule from './requiredDocuments'

Vue.use(Vuex)

// Vuex module names
export const AUTH_MOD = 'auth'
export const REQUIRED_DOCUMENTS_MOD = 'requiredDocuments'
export const REFERRAL_PORTAL_MOD = 'referralPortal'

// Mutations
export const SET_READY = 'setReady'
export const SET_CONFIG = 'setConfig'
const SET_ACTIVE_DRAFT = 'setActiveDraft'
const SET_ACTIVE_DOCUMENT = 'setActiveDocument'
export const SET_USER_GATEWAY_FEATURES = 'setUserFeatures'

// Actions
export const INIT = 'init'
export const FETCH_DRAFT = 'fetchDraft'
export const FETCH_DRAFT_SUPPLIER = 'fetchDraftSupplier'
export const CLEAR_DRAFT = 'clearDraft'
export const FETCH_DOCUMENT = 'fetchDocument'
export const CLEAR_DOCUMENT = 'clearDocument'

// Getters
export const APP_CONFIG = 'appConfig'
export const ACTIVE_DRAFT = 'activeDraft'
export const ACTIVE_DOCUMENT = 'activeDocument'
export const SUPPLIERS = 'suppliers'
export const USER_GATEWAY_FEATURES = 'userGatewayFeatures'

export interface RootState {
  ready: boolean
  config: AppConfig | null
  activeDraft: BankDraft | null
  activeDocument: BankDocument | null
  userGatewayFeatures: GatewayFeatures | null
}

const actions: ActionTree<RootState, RootState> = {
  async [INIT]({ commit, dispatch }) {
    return await dispatch(`${AUTH_MOD}/${INIT}`).then(() => commit(SET_READY, true))
  },
  async [FETCH_DRAFT]({ commit }, { draftId }) {
    return await apolloClient()
      .query({
        query: queries.DRAFT_QUERY,
        variables: {
          where: { id: draftId }
        }
      })
      .then(result => {
        const draft = get(result, 'data.draft.nodes.0', {})
        if (Object.keys(draft).length) {
          commit(SET_ACTIVE_DRAFT, draft)
        } else {
          ToastProgrammatic.open({
            type: 'is-danger',
            message: 'Draft could not be found.'
          })
          router.push({ name: 'default' })
        }
      })
      .catch((error: ApolloError) => {
        const { message } = error
        ToastProgrammatic.open({
          type: 'is-danger',
          message
        })
      })
  },
  async [FETCH_DRAFT_SUPPLIER]({ commit }, { draftId }) {
    return await apolloClient()
      .query({
        query: queries.SUPPLIER_DRAFT_QUERY,
        variables: {
          where: { id: draftId }
        }
      })
      .then(result => {
        const draft = get(result, 'data.draft.nodes.0', {})
        if (Object.keys(draft).length) {
          commit(SET_ACTIVE_DRAFT, draft)
        } else {
          ToastProgrammatic.open({
            type: 'is-danger',
            message: 'Draft could not be found.'
          })
          router.push({ name: 'default' })
        }
      })
      .catch((error: ApolloError) => {
        const { message } = error
        ToastProgrammatic.open({
          type: 'is-danger',
          message
        })
      })
  },
  [CLEAR_DRAFT]({ commit }) {
    if (state.activeDraft) {
      commit(SET_ACTIVE_DRAFT, null)
    }
  },
  async [FETCH_DOCUMENT]({ commit }, { documentId }) {
    return await apolloClient()
      .query({
        query: queries.DOCUMENT_QUERY,
        variables: {
          where: {
            id: documentId
          }
        }
      })
      .then(result => {
        const document = get(result, 'data.document.nodes.0', {})
        if (Object.keys(document).length) {
          commit(SET_ACTIVE_DOCUMENT, document)
        } else {
          ToastProgrammatic.open({
            type: 'is-danger',
            message: 'Document could not be found.'
          })
          router.push({ name: 'default' })
        }
      })
      .catch((error: ApolloError) => {
        const { message } = error
        ToastProgrammatic.open({
          type: 'is-danger',
          message
        })
      })
  },
  [CLEAR_DOCUMENT]({ commit }) {
    if (state.activeDocument) {
      commit(SET_ACTIVE_DOCUMENT, null)
    }
  }
}

const state: RootState = {
  ready: false,
  config: null,
  activeDraft: null,
  activeDocument: null,
  userGatewayFeatures: null
}

const mutations: MutationTree<RootState> = {
  async [SET_USER_GATEWAY_FEATURES](state, userGatewayFeatures) {
    state.userGatewayFeatures = await userGatewayFeatures
  },
  [SET_READY](state, status: boolean) {
    state.ready = status
  },
  [SET_CONFIG](state, config: AppConfig) {
    state.config = config
  },
  [SET_ACTIVE_DRAFT](state, draft: BankDraft) {
    state.activeDraft = draft
  },
  [SET_ACTIVE_DOCUMENT](state, document: BankDocument) {
    state.activeDocument = document
  }
}

const getters: GetterTree<RootState, RootState> = {
  isReady({ ready }) {
    return ready
  },
  isAuthenticated() {
    return store.getters[`${AUTH_MOD}/${IS_LOGGED_IN}`]
  },
  [APP_CONFIG]: ({ config }) => config,
  [ACTIVE_DRAFT]: ({ activeDraft }) => activeDraft,
  [ACTIVE_DOCUMENT]: ({ activeDocument }) => activeDocument,
  [USER_GATEWAY_FEATURES]: ({ userGatewayFeatures }) => userGatewayFeatures
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    [AUTH_MOD]: cognitoModule,
    [REQUIRED_DOCUMENTS_MOD]: requiredDocumentsModule,
    [REFERRAL_PORTAL_MOD]: referralPortalModule
  }
})

export default store
export * from './cognito'
export * from './requiredDocuments'
