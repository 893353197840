import { queries } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import { Entity, ReferralPortalMember } from '@/graphql/types'
import { RootState } from '@/store'
import { ApolloQueryResult } from 'apollo-client'
import { ToastProgrammatic } from 'buefy'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

interface ReferralPortalState {
  member: ReferralPortalMember | null
  entity: Entity | null
}

// Actions
export const FETCH_CURRENT_MEMBER_AND_ENTITY = 'fetchMemberAndEntity'

// Mutations
const SET_MEMBER = 'setMember'
const SET_ENTITY = 'setEntity'

// Getters
export const REFERRAL_PORTAL = 'referralPortal'

const initialState: ReferralPortalState = {
  member: null,
  entity: null
}

const state: ReferralPortalState = { ...initialState }

const getters: GetterTree<ReferralPortalState, RootState> = {
  [REFERRAL_PORTAL]: state => state
}

const actions: ActionTree<ReferralPortalState, RootState> = {
  async [FETCH_CURRENT_MEMBER_AND_ENTITY]({ commit, dispatch }) {
    return await apolloClient()
      .query({
        query: queries.GET_REFERRAL_PORTAL_MY_MEMBER
      })
      .then(async ({ data }: ApolloQueryResult<{ referralPortalMyMember: ReferralPortalMember }>) => {
        commit(SET_MEMBER, data.referralPortalMyMember)

        const { data: entityData } = await apolloClient().query({
          query: queries.GET_REFERRAL_ENTITY_BASE_BY_ID,
          variables: {
            id: data.referralPortalMyMember.entityId
          }
        })
        commit(SET_ENTITY, entityData.referralPortalEntities.nodes[0])
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  }
}

const mutations: MutationTree<ReferralPortalState> = {
  [SET_MEMBER](state, member: ReferralPortalMember) {
    state.member = member
  },
  [SET_ENTITY](state, entity: Entity) {
    state.entity = entity
  }
}

const referralPortal: Module<ReferralPortalState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default referralPortal
