import { queries, subscriptions } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import { RequiredDocument } from '@/graphql/types'
import { RootState } from '@/store'
import { ApolloError, ApolloQueryResult } from 'apollo-client'
import { ToastProgrammatic } from 'buefy'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

interface RequiredDocumentState {
  requiredDocuments: RequiredDocument[]
  // eslint-disable-next-line  no-undef
  requiredDocumentsSubscription: ZenObservable.Subscription | null
}

interface SetRequiredDocumentsInput {
  requiredDocuments: RequiredDocument[]
}

// Actions
export const FETCH_REQUIRED_DOCUMENTS = 'fetchRequiredDocuments'
export const SUBSCRIBE_REQUIRED_DOCUMENTS = 'subscribeRequiredDocuments'
export const CLEAR_REQUIRED_DOCUMENTS = 'clearRequiredDocuments'

// Mutations
const SET_REQUIRED_DOCUMENTS_SUBSCRIPTION = 'setRequiredDocumentsSubscription'
const SET_REQUIRED_DOCUMENTS = 'setRequiredDocuments'
// Getters
export const REQUIRED_DOCUMENTS = 'requiredDocuments'

const initialState: RequiredDocumentState = {
  requiredDocuments: [],
  requiredDocumentsSubscription: null
}

const state: RequiredDocumentState = { ...initialState }

const getters: GetterTree<RequiredDocumentState, RootState> = {
  [REQUIRED_DOCUMENTS]: ({ requiredDocuments }) => requiredDocuments
}

const actions: ActionTree<RequiredDocumentState, RootState> = {
  async [FETCH_REQUIRED_DOCUMENTS]({ commit, dispatch }, data) {
    const draftId = data?.draftId
    if (!draftId) return
    return await apolloClient()
      .query({
        query: queries.GET_DRAFT_REQUIRED_DOCUMENTS,
        variables: {
          where: { id: draftId }
        }
      })
      .then(({ data }: ApolloQueryResult<{ draft: any }>) => {
        const requiredDocuments = data.draft.nodes[0].requiredDocuments
        commit(SET_REQUIRED_DOCUMENTS, { requiredDocuments, draftId })
        dispatch(SUBSCRIBE_REQUIRED_DOCUMENTS, { draftId })
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  [SUBSCRIBE_REQUIRED_DOCUMENTS]({ commit, dispatch }, { draftId }) {
    if (state.requiredDocumentsSubscription) {
      state.requiredDocumentsSubscription.unsubscribe()
    }
    const subscription = apolloClient()
      .subscribe({
        query: subscriptions.REQUIRED_DOCUMENTS_UPDATED_QUERY,
        variables: {
          draftId
        }
      })
      .subscribe({
        next: () => {
          dispatch(FETCH_REQUIRED_DOCUMENTS, { draftId })
        },
        error: ({ message }: ApolloError) =>
          ToastProgrammatic.open({
            type: 'is-danger',
            message
          })
      })
    commit(SET_REQUIRED_DOCUMENTS_SUBSCRIPTION, subscription)
  },
  [CLEAR_REQUIRED_DOCUMENTS]({ commit }) {
    if (state.requiredDocumentsSubscription) {
      state.requiredDocumentsSubscription.unsubscribe()
    }
    commit(CLEAR_REQUIRED_DOCUMENTS)
  }
}

const mutations: MutationTree<RequiredDocumentState> = {
  [SET_REQUIRED_DOCUMENTS](state, requiredDocuments: SetRequiredDocumentsInput) {
    if (requiredDocuments) {
      state.requiredDocuments = requiredDocuments.requiredDocuments
    } else {
      state.requiredDocuments = []
    }
  },
  // eslint-disable-next-line  no-undef
  [SET_REQUIRED_DOCUMENTS_SUBSCRIPTION](state, subscription: ZenObservable.Subscription) {
    state.requiredDocumentsSubscription = subscription
  },
  [CLEAR_REQUIRED_DOCUMENTS](state) {
    state.requiredDocuments = initialState.requiredDocuments
    if (state.requiredDocuments) {
      state.requiredDocuments = []
    }
  }
}

const requiredDocuments: Module<RequiredDocumentState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default requiredDocuments
