import { AppConfig } from '@/config'
import Auth from '@aws-amplify/auth'

export const configure = (config: AppConfig) => {
  Auth.configure({ ...config.cognito })
}
export const signIn = async (username: string, password: string) => await Auth.signIn(username, password)
export const answerMfaChallenge = async (user: any, code: string) => await Auth.confirmSignIn(user, code)
export const answerPasswordChallenge = async (user: any, password: string) =>
  await Auth.completeNewPassword(user, password)
export const signOut = async () => await Auth.signOut()
export const currentSession = async () => await Auth.currentSession()
export const currentUser = async () => await Auth.currentAuthenticatedUser()
export const currentUserInfo = async () => await Auth.currentUserInfo()
export const forgotPassword = async (username: string) => await Auth.forgotPassword(username)
export const forgotPasswordSubmit = async (username: string, code: string, newPassword: string) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword)
}
