import store, { INIT } from '@/store'
import { getRolesFromBitwiseInteger } from '@/utils/auth'
import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { BankUserRole } from '~/types'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'is-active'
})

router.beforeEach(async (to, _from, next) => {
  if (!store.getters.isReady) {
    await store.dispatch(INIT)
  }

  // fetch user details after the auth has been INIT
  const { isAuthenticated, ['auth/getUserRoles']: userRoleBitwise } = store.getters

  if (_from.query && _from.query.next) {
    const nextPath = String(_from.query.next)
    if (nextPath && nextPath != to.fullPath) {
      localStorage.setItem('next-path', nextPath)
    }
  }

  // e.g. login page
  if (to.meta?.anonymous && isAuthenticated) {
    return next({ name: 'default' })
  }

  if (!to.meta?.anonymous && !isAuthenticated) {
    const query = to.fullPath === '/' ? {} : { next: to.fullPath }
    return next({ name: 'account', query: query })
  }

  if (isUserAuthorised(to, userRoleBitwise)) {
    return next()
  }

  return next('/page-not-found')
})

const isUserAuthorised = (to: Route, userRoleBitwise: string) => {
  const authorizedRoles: BankUserRole[] | undefined = to.meta?.authorize
  const userRoles = getRolesFromBitwiseInteger(userRoleBitwise)

  // no authorisation defined for the route (null|undefined)
  // or, the authorisation is defined and user has the role
  if (authorizedRoles == null || authorizedRoles.some(role => userRoles.includes(role))) {
    return true
  }

  return false
}

export default router
