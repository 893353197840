import { BankUserRole as Role } from '~/types'

const BitwiseRole = {
  [Role.ASSURO_RELATIONSHIP_MANAGER]: 1,
  [Role.ASSURO_GENERAL]: 2,
  [Role.EXTERNAL_SUPPLIER]: 4,
  [Role.EXTERNAL_REFERRALPORTAL_ADMIN]: 8,
  [Role.EXTERNAL_REFERRALPORTAL_REFERRER]: 16
}

export const getRolesFromBitwiseInteger = (value: number | string): Role[] => {
  const intValue = typeof value === 'string' ? parseInt(value) : value

  const roles: Role[] = []

  if (intValue & BitwiseRole[Role.ASSURO_RELATIONSHIP_MANAGER]) roles.push(Role.ASSURO_RELATIONSHIP_MANAGER)
  if (intValue & BitwiseRole[Role.ASSURO_GENERAL]) roles.push(Role.ASSURO_GENERAL)
  if (intValue & BitwiseRole[Role.EXTERNAL_SUPPLIER]) roles.push(Role.EXTERNAL_SUPPLIER)
  if (intValue & BitwiseRole[Role.EXTERNAL_REFERRALPORTAL_ADMIN]) roles.push(Role.EXTERNAL_REFERRALPORTAL_ADMIN)
  if (intValue & BitwiseRole[Role.EXTERNAL_REFERRALPORTAL_REFERRER]) roles.push(Role.EXTERNAL_REFERRALPORTAL_REFERRER)

  return roles
}

export const hasAnyUserRole = (bitwiseRole: number | string, rolesInput: Role[]): boolean => {
  const roles = getRolesFromBitwiseInteger(bitwiseRole)
  return roles.some(role => rolesInput.includes(role))
}

export const isAssuroUser = (bitwiseRole: number | string): boolean => {
  const rolesInput = [Role.ASSURO_GENERAL, Role.ASSURO_RELATIONSHIP_MANAGER]
  return hasAnyUserRole(bitwiseRole, rolesInput)
}

export const isExternalSupplierUser = (bitwiseRole: number | string): boolean => {
  const rolesInput = [Role.EXTERNAL_SUPPLIER]
  return hasAnyUserRole(bitwiseRole, rolesInput)
}

export const isReferralAdminUser = (bitwiseRole: number | string): boolean => {
  const rolesInput = [Role.EXTERNAL_REFERRALPORTAL_ADMIN]
  return hasAnyUserRole(bitwiseRole, rolesInput)
}

export const isReferrerUser = (bitwiseRole: number | string): boolean => {
  const rolesInput = [Role.EXTERNAL_REFERRALPORTAL_REFERRER]
  return hasAnyUserRole(bitwiseRole, rolesInput)
}

export const isReferralPortalUser = (bitwiseRole: number | string): boolean => {
  const rolesInput = [Role.EXTERNAL_REFERRALPORTAL_REFERRER, Role.EXTERNAL_REFERRALPORTAL_ADMIN]
  return hasAnyUserRole(bitwiseRole, rolesInput)
}
